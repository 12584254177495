/* index.css */

body {
  font-family: Arial, sans-serif;
  background-color: #444444;
  margin: 0;
  padding: 0;
}

/* Add more global styles as needed */
