/* App.css */

/* Reset CSS */
body,
html,
div,
header,
main,
nav,
section,
article,
footer,
hgroup,
menu,
figure,
figcaption,
ul,
li,
ol,
form,
fieldset,
legend,
label,
input,
button,
textarea,
p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

/* Set box-sizing to border-box for all elements */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Apply global styles */

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.navbar {
  background-color: #444;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

.navbar ul {
  list-style: none;
  padding: 0;
}

.navbar li {
  display: inline-block;
  margin: 0 10px;
}

.navbar a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: #ffcc00;
}

.main-content {
  padding: 20px 0;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}
