@import url("https://fonts.googleapis.com/css?family=Exo+2&display=swap");

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Exo 2", sans-serif;
  background: #1b1b1a;
  color: white;
  font-size: 14px;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.App {
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
}


.ShowCase {
  margin-left: 0px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  color: #7e7e7e; /* Adjust color if needed */
  margin-bottom: 60px;
  padding-left: 10PX;
  margin-top: -130px;
  
  
}


.ShowCase li {
  
  margin: 0 8px;
  top: 40%;
}

.Cinema {
  margin-bottom: 18px;
  perspective: 400px;

  display: grid;
  place-items: center;
  grid-gap: 7px;
}

.Cinema .screen {
  height: 53px;
  background: rgb(255, 255, 255);
  width: 100%;
  transform: rotateX(-30deg) scale(.9);
  box-shadow: 0 3px 10px 2px;
  margin-left: 37px;
  padding: 35px;
  margin-bottom:35px;
}




.row {
  display: flex;
  justify-content: flex-end; /* Align seats to the right */
  width: 100%;
  margin-bottom:1px;
}


.seat {
  width: 27px;
  padding-bottom: 10px;
  height: 24px;
  margin-right: 4px;
  background-color: #bbbaba;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.seat:last-child {
  margin-right: 0;
}

.seat.selected {
  background-color: #eff165;
}

.seat.occupied {
  background-color: #464141e7;
  cursor: not-allowed;
}

.seat.ghost {
  visibility: hidden; /* Maintain layout but invisible */
}




.info {
color: #000000;
font-size: 18px;
border-radius: 20px;
background: rgb(255, 255, 255);
position: absolute;
top: 89.7%; /* Center vertically */
left: 13%;
margin: 0 7px 7px 7px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-self: flex-start;
padding: 12px 18px 13px 22px;
width: 320px;
box-sizing: border-box;
margin-bottom: 30px;
}
.seat-icon {
  position: absolute;
  top: 50%;
  left: 110px;
  transform: translateY(-50%);
  
}


.proceed-button {
  position: absolute; /* Position the button absolutely within the.info container */
  top: 93.3%; /* Center vertically */
  left: 54%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Ensure the button is centered perfectly */
  height: 40px;
  width: 160px;
  font-size: 18px;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
}

.proceed-button:hover {
  background-color: #383636;
}

.proceed-button:disabled {
  background-color: #352a2a8e;
  cursor: not-allowed;
}

/* Media Query for Mobile View */
@media only screen and (max-width: 600px) {
  .Cinema .screen {
    width: 95%;
    margin-left: 5%;
    margin-bottom: 48px;
  }

  .seat {
    width: 20px;
    padding-bottom: 8px;
    height: 17px;
    margin-right: 3px;
  }

  .seat.selected {
    background-color: #eaf73c;
  }

  .seat.occupied {
    background-color: #414141;
  }
}
@import url("https://fonts.googleapis.com/css?family=Exo+2&display=swap");

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Exo 2", sans-serif;
  background: #1b1b1a;
  color: white;
  font-size: 14px;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.App {
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
}


.ShowCase {
  margin-left: 0px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  color: #7e7e7e; /* Adjust color if needed */
  margin-bottom: 60px;
  padding-left: 10PX;
  margin-top: -130px;
  
  
}


.ShowCase li {
  
  margin: 0 8px;
  top: 40%;
}

.Cinema {
  margin-bottom: 18px;
  perspective: 400px;

  display: grid;
  place-items: center;
  grid-gap: 7px;
}

.Cinema .screen {
  height: 53px;
  background: rgb(255, 255, 255);
  width: 100%;
  transform: rotateX(-30deg) scale(.9);
  box-shadow: 0 3px 10px 2px;
  margin-left: 37px;
  padding: 35px;
  margin-bottom:35px;
}




.row {
  display: flex;
  justify-content: flex-end; /* Align seats to the right */
  width: 100%;
  margin-bottom:1px;
}


.seat {
  width: 27px;
  padding-bottom: 10px;
  height: 24px;
  margin-right: 4px;
  background-color: #bbbaba;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.seat:last-child {
  margin-right: 0;
}

.seat.selected {
  background-color: #eff165;
}

.seat.occupied {
  background-color: #464141e7;
  cursor: not-allowed;
}

.seat.ghost {
  visibility: hidden; /* Maintain layout but invisible */
}

.info {
  font-size: 18px;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  position: fixed;  /* Keeps the info container fixed at a specific location */
  bottom: 20px;  /* Positioned at the bottom of the viewport */
  left: 50%;  /* Centered horizontally in the viewport */
  transform: translateX(-50%);  /* Offset the translation to truly center it */
  padding: 12px 18px;
  width: 80%;  /* Responsive width */
  max-width: 320px;  /* Maximum width control */
  display: flex;
  justify-content: space-between;  /* Distributes children with space between them */
  align-items: center;  /* Ensures children are centered vertically */
}

/* Flex items for text that are supposed to be at the far ends of the info container */
.info .text-left, .info .text-right {
  flex: 1;  /* Allows these items to grow and take up space, pushing the button to the center */
  text-align: center;  /* Adjust text alignment as needed */
}

.proceed-button {
  flex: 0 1 auto;  /* Button can grow if needed but starts with the size given */
  height: 40px;
  width: 140px;
  left: 50cqw;
  
  font-size: 18px;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.proceed-button:hover {
  background-color: #383636;
}

.proceed-button:disabled {
  background-color: #352a2a8e;
  cursor: not-allowed;
}

/* Media Query for Mobile View */
@media only screen and (max-width: 600px) {
  .Cinema .screen {
    width: 95%;
    margin-left: 5%;
    margin-bottom: 48px;
  }

  .seat {
    width: 20px;
    padding-bottom: 8px;
    height: 17px;
    margin-right: 3px;
  }

  .seat.selected {
    background-color: #eaf73c;
  }

  .seat.occupied {
    background-color: #414141;
  }
}
