@import url(https://fonts.googleapis.com/css2?family=Parkinsans:wght@300..800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Exo+2&display=swap);
@import url(https://fonts.googleapis.com/css?family=Exo+2&display=swap);
/* index.css */

body {
  font-family: Arial, sans-serif;
  background-color: #444444;
  margin: 0;
  padding: 0;
}

/* Add more global styles as needed */

/* App.css */

/* Reset CSS */
body,
html,
div,
header,
main,
nav,
section,
article,
footer,
hgroup,
menu,
figure,
figcaption,
ul,
li,
ol,
form,
fieldset,
legend,
label,
input,
button,
textarea,
p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

/* Set box-sizing to border-box for all elements */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Apply global styles */

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.navbar {
  background-color: #444;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

.navbar ul {
  list-style: none;
  padding: 0;
}

.navbar li {
  display: inline-block;
  margin: 0 10px;
}

.navbar a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: #ffcc00;
}

.main-content {
  padding: 20px 0;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

/* NavigationBar.css */
.NavigationBar_navBar__b84fr {
    font-family: 'Courier New', Courier, monospace;
    background-color: #ffffff; /* Dark background for the nav bar */
    color: rgb(0, 0, 0);
    padding: 10px 30px; /* Adjusted padding for better spacing */
    text-align: center;
    width: 100%; /* Ensures it spans the full width of the viewport */
    position: fixed; /* Fixes the navbar at the top of the viewport */
    top: 0; /* Aligns the navbar at the top */
    left: 0; /* Aligns the navbar to the left edge of the viewport */
    z-index: 1000; /* Ensures the navbar stays above other content */
    box-shadow: 0 2px 4px rgba(0,0,0,0.5); /* Adds shadow for better visibility */
    display: flex; /* Added flex display to manage child elements */
    justify-content: space-between; /* Aligns children to the space between */
    align-items: center; /* Centers items vertically */
}

.NavigationBar_navList__1hQqy {
    list-style: none;
    padding: 0;
    margin: 0; /* Removes default margin to align items properly */
    display: flex; /* Flex display to align nav items inline */
}

.NavigationBar_navItem__2KFfQ {
    margin: 10px; /* Spacing between items */
    cursor: pointer;
    transition: color 0.3s; /* Smooth transition for hover effects */
}

.NavigationBar_navItem__2KFfQ:hover {
    color: #ccc; /* Lighten color on hover for a visual feedback */
}

.NavigationBar_navItem__2KFfQ svg {
    margin-right: 0px; /* Adds a small space between the icon and the text */
    vertical-align: center; /* Aligns the icon vertically with the text */
}

.NavigationBar_booknow__s9Ne9 {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.NavigationBar_logo__3fq5G {
    height: 30px; /* Set a base height */
    width: auto; /* Maintain the aspect ratio */
    transform: scale(1.5); /* Scale the logo up */
    max-height: 60px; /* Ensure it does not exceed the navbar height */
    transition: transform 0.3s;
    margin-left: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 7px;
     /* Smooth transition for hover effects */
}

.NavigationBar_logo__3fq5G:hover {
    transform: scale(1.6); /* Slightly enlarge the logo on hover for visual feedback */
}

  /* Footer.module.css */
  .Footer_footer__1kXAM {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 20px 30px;
    position: bottom;
    bottom: 0;
    width: 100%;
    border-radius: 8px;
  }
   
/* HomePage.module.css */
@keyframes HomePage_gradient__3yPIH {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/* HomePage.module.css */
/* HomePage.module.css */
.HomePage_fadeIn__2I9Pi {
  opacity: 0;
  animation: HomePage_fadeInAnimation__3zP4y 0.5s forwards;
  animation-delay: calc(0.1s * var(--index)); /* each letter will delay a bit more based on its index */
}

@keyframes HomePage_fadeInAnimation__3zP4y {
  to {
    opacity: 1;
  }
}
@keyframes HomePage_gradientAnimation__3OuUk {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

/* Welcome Section */
.HomePage_welcomeSection__3MCQk {
  height: 70vh; /* Full viewport height */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(180deg, #ff7eb3, #ff758c, #be0aff, #8324ff , #e4c6ff); /* Gradient colors */
  background-size: 300% 300%; /* Ensure the gradient has a wide range for smooth animation */
  animation: HomePage_gradientAnimation__3OuUk 10s ease infinite; /* Slow animation */
  color: #ffffff;
  padding: 20px;
  position: relative; /* Keep elements like the scroll-down symbol inside */
}
.HomePage_description__2VYh7 {
  font-size: 1.2em;
  color: #000000;
  margin-top: 20px;
  font-family: 'Courier New', Courier, monospace;
}

/* Scroll Animation */
.HomePage_scrollSection__TSM6U {
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.HomePage_scrollSection__TSM6U.HomePage_fadeIn__2I9Pi {
  opacity: 1;
  transform: translateY(0);
}

/* Scroll Down Symbol */
.HomePage_scrollDown__2Twda {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem; /* Size of the arrow */
  color: #ffffff;
  animation: HomePage_bounce__3usmU 1.5s infinite;
  cursor: pointer;
}

@keyframes HomePage_bounce__3usmU {
  0%, 20%, 50%, 80%, 100% {
    transform: translate(-50%, 0);
  }
  40% {
    transform: translate(-50%, -10px);
  }
  60% {
    transform: translate(-50%, -5px);
  }
}

.HomePage_scrollDown__2Twda span {
  font-size: 2rem; /* Arrow size */
  display: block;
}


html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling at the root level */
}
.HomePage_welcomeHeading__3kwXu {
  font-family: park;
  font-size: 2.5em; /* Larger text size */
  color: #000000; /* White color text, adjust if needed */
  font-family: "Parkinsans", sans-serif;
}

.HomePage_homepage__316t8 {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  padding: 50px;
  margin-top: 60px;
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  animation: HomePage_gradient__3yPIH 15s ease infinite;
  background-size: cover;
  background-repeat: no-repeat;
}

h4 {
  position: relative;
  color: rgb(255, 187, 0);
  top: 10%;
  margin-top: 190px;
  font-weight: 100;
  font-family: 'Courier New', Courier, monospace;
}

.HomePage_movieList__35Pym {
  
  border-radius: 8px;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  gap: 20px;
}

.HomePage_movieItem__1_LUA {
  display: flex;
  
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.381);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden; /* Ensures no overflow of child elements */
  z-index: 2; /* Ensure movie items are above the popcorn */
}

.HomePage_movieItem__1_LUA:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.HomePage_moviePoster__3oB02 {
  width: 100%;  /* Ensure it spans the full width of the container */
  height: 100%; /* Ensure it spans the full height of the container */
  object-fit: cover; /* Cover the area completely, may crop the image */
  position: absolute; /* Position it absolutely within the parent container */
  top: 0;
  left: 0;
}

.HomePage_movieDetails__2vLRl {
  position: relative; /* Higher stack order to make text visible on top of image */
  z-index: 2; /* Ensure details are above the popcorn */
  padding: 10px;
  background: rgba(0, 0, 0, 0); /* Semi-transparent background for legibility */
  width: 100%; /* Ensures details cover the image horizontally */
}

.HomePage_movieTitle__2Fw8D {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #ffffff;
}

.HomePage_movieVenue__3PbMv {
  font-size: 1em;
  color: #ffffff;
}

.HomePage_movieItem__1_LUA h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #ffffff;
}

.HomePage_movieItem__1_LUA p {
  font-size: 1em;
  color: #ffffff;
}

.HomePage_movieTitle__2Fw8D, .HomePage_movieVenue__3PbMv {
  text-shadow: 2px 2px 8px rgb(0, 0, 0);
}
/* Movies Section */
.HomePage_moviesSection__2ykKb {
  margin-top: -110px;
  padding: 20px;
  background: #20143400; /* Differentiated background for contrast */
}

@media screen and (orientation: landscape) and (min-width: 1024px) {
  /* Landscape-specific styles for desktop sizes */
  @keyframes HomePage_gradientAnimation__3OuUk {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .HomePage_welcomeSection__3MCQk {
    flex-direction: row; /* Adjust layout for horizontal space */
    height: 80vh; /* Reduce height since width is dominant */
    width: 70vi;
    padding: 40px;
    border-radius: 9px;
    background: linear-gradient(-45deg,  #ff7eb3, #ff758c, #be0aff, #8324ff , #e4c6ff); /* Gradient colors */
  background-size: 300% 300%; /* Ensure the gradient has a wide range for smooth animation */
  animation: HomePage_gradientAnimation__3OuUk 10s ease infinite; /* Slow animation */
  }

  .HomePage_description__2VYh7 {
    font-size: 1.2em;
    color: #000000;
    margin-top: 300px;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
  }

  .HomePage_movieList__35Pym {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Wider movie items */
    grid-gap: 30px;
    gap: 30px; /* Increase spacing between items */
  }

  

 

  .HomePage_movieDetails__2vLRl {
    width: 60%; /* Allow more space for details */
    padding-left: 20px; /* Add space between poster and details */
  }
  .HomePage_welcomeHeading__3kwXu{
    position: absolute;
    font-size: 60px;
  }
}

.MovieCard_MovieCard__1Cbn3 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  position: relative;
  width: 100vw;  
  height: auto; /* Change from 100vh to auto to allow content height to dictate overall height */
  min-height: 100vh; /* Ensures it's at least the height of the viewport */
  margin: 0;
  overflow: visible; /* Make sure overflow is visible or auto */
}

.MovieCard_backgroundImg__106nn {
  position: absolute; /* Ensures it covers the MovieCard */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover; /* Ensures the image covers the full view */
}

.MovieCard_imgGradient__1KlKB {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, rgba(0,0,0,1), transparent 90%);
}

.MovieCard_MovieCard_content__T1iVA {
  position: relative; /* Changed from absolute to relative */
  color: white; 
  text-align: center;
  z-index: 10; 
  width: 85%; /* Adaptive width for better responsiveness */
  padding: 20px; /* Adds padding for readability */
  margin: auto; /* Centers the content */
  margin-top: 60vh; /* Adjust based on the content's visual needs */
}

.MovieCard_MovieCard_title__1XBPk, .MovieCard_MovieCard_description__1Scau, .MovieCard_MovieCard_eventDetails__2st0E, .MovieCard_MovieCard_button__1YC48 {
  margin-bottom: 20px; /* Consistent spacing for elements */
}

/* Ensure button is accessible */
.MovieCard_MovieCard_button__1YC48 {
  display: inline-block; /* Adjust as needed for layout */
  font-size: 1em;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.MovieCard_MovieCard_button__1YC48:hover {
  background-color: #0056b3;
}

/* Media Queries for responsive adjustments */
@media (max-width: 768px) {
  .MovieCard_MovieCard_content__T1iVA {
    width: 95%; /* More width for smaller screens */
  }
}

@media (max-width: 480px) {
  .MovieCard_MovieCard_content__T1iVA {
    width: 95%; /* Adjust width and margin for very small screens */
    margin-top: 50vh; /* Less vertical space on mobile devices */
  }
}

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Exo 2", sans-serif;
  background: #1b1b1a;
  color: white;
  font-size: 14px;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.App {
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
}


.ShowCase {
  margin-left: 0px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  color: #7e7e7e; /* Adjust color if needed */
  margin-bottom: 60px;
  padding-left: 10PX;
  margin-top: -130px;
  
  
}


.ShowCase li {
  
  margin: 0 8px;
  top: 40%;
}

.Cinema {
  margin-bottom: 18px;
  perspective: 400px;

  display: grid;
  place-items: center;
  grid-gap: 7px;
}

.Cinema .screen {
  height: 53px;
  background: rgb(255, 255, 255);
  width: 100%;
  transform: rotateX(-30deg) scale(.9);
  box-shadow: 0 3px 10px 2px;
  margin-left: 37px;
  padding: 35px;
  margin-bottom:35px;
}




.row {
  display: flex;
  justify-content: flex-end; /* Align seats to the right */
  width: 100%;
  margin-bottom:1px;
}


.seat {
  width: 27px;
  padding-bottom: 10px;
  height: 24px;
  margin-right: 4px;
  background-color: #bbbaba;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.seat:last-child {
  margin-right: 0;
}

.seat.selected {
  background-color: #eff165;
}

.seat.occupied {
  background-color: #464141e7;
  cursor: not-allowed;
}

.seat.ghost {
  visibility: hidden; /* Maintain layout but invisible */
}




.info {
color: #000000;
font-size: 18px;
border-radius: 20px;
background: rgb(255, 255, 255);
position: absolute;
top: 89.7%; /* Center vertically */
left: 13%;
margin: 0 7px 7px 7px;
display: flex;
flex-direction: row;
justify-content: space-between;
align-self: flex-start;
padding: 12px 18px 13px 22px;
width: 320px;
box-sizing: border-box;
margin-bottom: 30px;
}
.seat-icon {
  position: absolute;
  top: 50%;
  left: 110px;
  transform: translateY(-50%);
  
}


.proceed-button {
  position: absolute; /* Position the button absolutely within the.info container */
  top: 93.3%; /* Center vertically */
  left: 54%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Ensure the button is centered perfectly */
  height: 40px;
  width: 160px;
  font-size: 18px;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
}

.proceed-button:hover {
  background-color: #383636;
}

.proceed-button:disabled {
  background-color: #352a2a8e;
  cursor: not-allowed;
}

/* Media Query for Mobile View */
@media only screen and (max-width: 600px) {
  .Cinema .screen {
    width: 95%;
    margin-left: 5%;
    margin-bottom: 48px;
  }

  .seat {
    width: 20px;
    padding-bottom: 8px;
    height: 17px;
    margin-right: 3px;
  }

  .seat.selected {
    background-color: #eaf73c;
  }

  .seat.occupied {
    background-color: #414141;
  }
}

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Exo 2", sans-serif;
  background: #1b1b1a;
  color: white;
  font-size: 14px;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.App {
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
}


.ShowCase {
  margin-left: 0px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  color: #7e7e7e; /* Adjust color if needed */
  margin-bottom: 60px;
  padding-left: 10PX;
  margin-top: -130px;
  
  
}


.ShowCase li {
  
  margin: 0 8px;
  top: 40%;
}

.Cinema {
  margin-bottom: 18px;
  perspective: 400px;

  display: grid;
  place-items: center;
  grid-gap: 7px;
}

.Cinema .screen {
  height: 53px;
  background: rgb(255, 255, 255);
  width: 100%;
  transform: rotateX(-30deg) scale(.9);
  box-shadow: 0 3px 10px 2px;
  margin-left: 37px;
  padding: 35px;
  margin-bottom:35px;
}




.row {
  display: flex;
  justify-content: flex-end; /* Align seats to the right */
  width: 100%;
  margin-bottom:1px;
}


.seat {
  width: 27px;
  padding-bottom: 10px;
  height: 24px;
  margin-right: 4px;
  background-color: #bbbaba;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  display: inline-block;
  align-items: center;
  justify-content: center;
}

.seat:last-child {
  margin-right: 0;
}

.seat.selected {
  background-color: #eff165;
}

.seat.occupied {
  background-color: #464141e7;
  cursor: not-allowed;
}

.seat.ghost {
  visibility: hidden; /* Maintain layout but invisible */
}

.info {
  font-size: 18px;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  position: fixed;  /* Keeps the info container fixed at a specific location */
  bottom: 20px;  /* Positioned at the bottom of the viewport */
  left: 50%;  /* Centered horizontally in the viewport */
  transform: translateX(-50%);  /* Offset the translation to truly center it */
  padding: 12px 18px;
  width: 80%;  /* Responsive width */
  max-width: 320px;  /* Maximum width control */
  display: flex;
  justify-content: space-between;  /* Distributes children with space between them */
  align-items: center;  /* Ensures children are centered vertically */
}

/* Flex items for text that are supposed to be at the far ends of the info container */
.info .text-left, .info .text-right {
  flex: 1 1;  /* Allows these items to grow and take up space, pushing the button to the center */
  text-align: center;  /* Adjust text alignment as needed */
}

.proceed-button {
  flex: 0 1 auto;  /* Button can grow if needed but starts with the size given */
  height: 40px;
  width: 140px;
  left: 50cqw;
  
  font-size: 18px;
  color: white;
  background-color: #000000;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.proceed-button:hover {
  background-color: #383636;
}

.proceed-button:disabled {
  background-color: #352a2a8e;
  cursor: not-allowed;
}

/* Media Query for Mobile View */
@media only screen and (max-width: 600px) {
  .Cinema .screen {
    width: 95%;
    margin-left: 5%;
    margin-bottom: 48px;
  }

  .seat {
    width: 20px;
    padding-bottom: 8px;
    height: 17px;
    margin-right: 3px;
  }

  .seat.selected {
    background-color: #eaf73c;
  }

  .seat.occupied {
    background-color: #414141;
  }
}

/* Payment.css */

body {
  margin: 0;
  padding: 0;
  font-family: 'Courier New', Courier, monospace;
  background: #000000; /* Sets the background color to black */
  color: white; /* Ensures text is visible against the black background */
  font-size: 14px;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.form-card h1 {
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  margin-bottom: 40px;
  padding-top: 0px;
  text-align: center; /* Center align the title */
}

input[type="text"], input[type="email"] {
  width: 90%; /* Responsive width */
  padding: 12px 20px;
  margin: 8px auto; /* Auto margins for center alignment */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  display: block; /* Block display to stack inputs */
}

input[type="text"]::placeholder, input[type="email"]::placeholder {
  color: #888;
}

input[type="text"]:focus, input[type="email"]:focus {
  border-color: #555;
  outline: none;
  box-shadow: 0 0 8px rgba(0,0,0,0.1);
}

.card-elements {
  margin: 20px auto;
  padding: 15px;
  background: #000000;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgb(0, 0, 0);
}

.card-element label {
  display: block;
  margin-bottom: 10px;
  color: #969696;
}

button {
  width: 90%;
  padding: 12px 20px;
  font-size: 16px;
  background-color: #4e4d1b;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
  margin: 10px auto 20px;
}

button:hover {
  background-color: #8ca032;
}

button:disabled {
  background-color: #ccc;
  cursor: default;
}

.StripeElement {
  padding: 12px 10px;
  border: 1px solid #000000;
  border-radius: 13px;
  width: 90%; /* Responsive width */
  margin: 10px auto; /* Center alignment */
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border-color: #a0a0a0;
}

.StripeElement::placeholder {
  color: #888;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

@media (max-width: 768px) {
  .form-card h1 {
      font-size: 20px; /* Smaller font size for better readability */
  }
}

@media (max-width: 480px) {
  .form-card h1 {
      font-size: 18px; /* Even smaller font size for mobile devices */
  }
  input[type="text"], input[type="email"] {
      padding: 10px 15px; /* Smaller padding for compact appearance */
      font-size: 14px; /* Smaller font size for better readability */
  }
  button {
      padding: 10px 25px; /* Adjust button padding for better fit */
      font-size: 14px; /* Adjust font size for readability */
  }
}

/* Confirmation.module.css */

.Confirmation_container__2KISK {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #000000;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.Confirmation_text__1e9TM {
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  font-size: calc(1.5em + 1vw);
  font-weight: bold;
  margin-bottom: 30px;
}

.Confirmation_yourImageClass__3bmNa {
  width: 100%; /* Responsive width */
  max-width: 375px; /* Maximum width */
  height: auto; /* Maintain aspect ratio */
  position: relative;
  margin: 0 auto; /* Center aligning */
}

.Confirmation_title__2fFCZ {
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(1.25em + 1vw);
  text-align: center;
  margin-bottom: 30px;
}

.Confirmation_venue__3MRGV, .Confirmation_time__f2TVP {
  display: flex;
  align-items: center;
  justify-content: start;
  color: #ffffff;
  font-size: calc(1em + 0.5vw);
  margin-bottom: 10px;
}

.Confirmation_locationIcon__2fiCr, .Confirmation_timesIcon__3qKVb {
  width: 24px;
  height: 20px;
  margin-right: 10px;
}

.Confirmation_seats__2nyvL, .Confirmation_totalamount__q0yPK, .Confirmation_email__3XlHh {
  color: #ffffff;
  font-size: calc(1em + 0.5vw);
  margin-bottom: 5px;
}

.Confirmation_qrcode__1Aim8 {
  display: block;
  margin: 20px auto;
}

.Confirmation_successMessage__6uNxX {
  text-align: center;
  margin-top: 20px;
  color: #ffffff;
}

.Confirmation_link__3ouzD {
  display: block;
  text-align: center;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.Confirmation_link__3ouzD:hover {
  background-color: #0056b3;
}

/* Media Query for smaller devices */
@media (max-width: 600px) {
  .Confirmation_container__2KISK {
      padding: 10px;
  }
  .Confirmation_text__1e9TM, .Confirmation_title__2fFCZ {
      font-size: calc(1em + 2vw); /* Smaller base size with a responsive addition */
  }
}

