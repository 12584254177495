@import url('https://fonts.googleapis.com/css2?family=Parkinsans:wght@300..800&display=swap');
/* HomePage.module.css */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/* HomePage.module.css */
/* HomePage.module.css */
.fadeIn {
  opacity: 0;
  animation: fadeInAnimation 0.5s forwards;
  animation-delay: calc(0.1s * var(--index)); /* each letter will delay a bit more based on its index */
}

@keyframes fadeInAnimation {
  to {
    opacity: 1;
  }
}
@keyframes gradientAnimation {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

/* Welcome Section */
.welcomeSection {
  height: 70vh; /* Full viewport height */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(180deg, #ff7eb3, #ff758c, #be0aff, #8324ff , #e4c6ff); /* Gradient colors */
  background-size: 300% 300%; /* Ensure the gradient has a wide range for smooth animation */
  animation: gradientAnimation 10s ease infinite; /* Slow animation */
  color: #ffffff;
  padding: 20px;
  position: relative; /* Keep elements like the scroll-down symbol inside */
}
.description {
  font-size: 1.2em;
  color: #000000;
  margin-top: 20px;
  font-family: 'Courier New', Courier, monospace;
}

/* Scroll Animation */
.scrollSection {
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}
.scrollSection.fadeIn {
  opacity: 1;
  transform: translateY(0);
}

/* Scroll Down Symbol */
.scrollDown {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem; /* Size of the arrow */
  color: #ffffff;
  animation: bounce 1.5s infinite;
  cursor: pointer;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate(-50%, 0);
  }
  40% {
    transform: translate(-50%, -10px);
  }
  60% {
    transform: translate(-50%, -5px);
  }
}

.scrollDown span {
  font-size: 2rem; /* Arrow size */
  display: block;
}


html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling at the root level */
}
.welcomeHeading {
  font-family: park;
  font-size: 2.5em; /* Larger text size */
  color: #000000; /* White color text, adjust if needed */
  font-family: "Parkinsans", sans-serif;
}

.homepage {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  padding: 50px;
  margin-top: 60px;
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  animation: gradient 15s ease infinite;
  background-size: cover;
  background-repeat: no-repeat;
}

h4 {
  position: relative;
  color: rgb(255, 187, 0);
  top: 10%;
  margin-top: 190px;
  font-weight: 100;
  font-family: 'Courier New', Courier, monospace;
}

.movieList {
  
  border-radius: 8px;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.movieItem {
  display: flex;
  
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.381);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden; /* Ensures no overflow of child elements */
  z-index: 2; /* Ensure movie items are above the popcorn */
}

.movieItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.moviePoster {
  width: 100%;  /* Ensure it spans the full width of the container */
  height: 100%; /* Ensure it spans the full height of the container */
  object-fit: cover; /* Cover the area completely, may crop the image */
  position: absolute; /* Position it absolutely within the parent container */
  top: 0;
  left: 0;
}

.movieDetails {
  position: relative; /* Higher stack order to make text visible on top of image */
  z-index: 2; /* Ensure details are above the popcorn */
  padding: 10px;
  background: rgba(0, 0, 0, 0); /* Semi-transparent background for legibility */
  width: 100%; /* Ensures details cover the image horizontally */
}

.movieTitle {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #ffffff;
}

.movieVenue {
  font-size: 1em;
  color: #ffffff;
}

.movieItem h2 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #ffffff;
}

.movieItem p {
  font-size: 1em;
  color: #ffffff;
}

.movieTitle, .movieVenue {
  text-shadow: 2px 2px 8px rgb(0, 0, 0);
}
/* Movies Section */
.moviesSection {
  margin-top: -110px;
  padding: 20px;
  background: #20143400; /* Differentiated background for contrast */
}

@media screen and (orientation: landscape) and (min-width: 1024px) {
  /* Landscape-specific styles for desktop sizes */
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .welcomeSection {
    flex-direction: row; /* Adjust layout for horizontal space */
    height: 80vh; /* Reduce height since width is dominant */
    width: 70vi;
    padding: 40px;
    border-radius: 9px;
    background: linear-gradient(-45deg,  #ff7eb3, #ff758c, #be0aff, #8324ff , #e4c6ff); /* Gradient colors */
  background-size: 300% 300%; /* Ensure the gradient has a wide range for smooth animation */
  animation: gradientAnimation 10s ease infinite; /* Slow animation */
  }

  .description {
    font-size: 1.2em;
    color: #000000;
    margin-top: 300px;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
  }

  .movieList {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Wider movie items */
    gap: 30px; /* Increase spacing between items */
  }

  

 

  .movieDetails {
    width: 60%; /* Allow more space for details */
    padding-left: 20px; /* Add space between poster and details */
  }
  .welcomeHeading{
    position: absolute;
    font-size: 60px;
  }
}
