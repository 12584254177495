/* Confirmation.module.css */

.container {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  background-color: #000000;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.text {
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  font-size: calc(1.5em + 1vw);
  font-weight: bold;
  margin-bottom: 30px;
}

.yourImageClass {
  width: 100%; /* Responsive width */
  max-width: 375px; /* Maximum width */
  height: auto; /* Maintain aspect ratio */
  position: relative;
  margin: 0 auto; /* Center aligning */
}

.title {
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: calc(1.25em + 1vw);
  text-align: center;
  margin-bottom: 30px;
}

.venue, .time {
  display: flex;
  align-items: center;
  justify-content: start;
  color: #ffffff;
  font-size: calc(1em + 0.5vw);
  margin-bottom: 10px;
}

.locationIcon, .timesIcon {
  width: 24px;
  height: 20px;
  margin-right: 10px;
}

.seats, .totalamount, .email {
  color: #ffffff;
  font-size: calc(1em + 0.5vw);
  margin-bottom: 5px;
}

.qrcode {
  display: block;
  margin: 20px auto;
}

.successMessage {
  text-align: center;
  margin-top: 20px;
  color: #ffffff;
}

.link {
  display: block;
  text-align: center;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.link:hover {
  background-color: #0056b3;
}

/* Media Query for smaller devices */
@media (max-width: 600px) {
  .container {
      padding: 10px;
  }
  .text, .title {
      font-size: calc(1em + 2vw); /* Smaller base size with a responsive addition */
  }
}
