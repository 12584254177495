  /* Footer.module.css */
  .footer {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    text-align: center;
    padding: 20px 30px;
    position: bottom;
    bottom: 0;
    width: 100%;
    border-radius: 8px;
  }
   