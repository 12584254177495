/* Payment.css */

body {
  margin: 0;
  padding: 0;
  font-family: 'Courier New', Courier, monospace;
  background: #000000; /* Sets the background color to black */
  color: white; /* Ensures text is visible against the black background */
  font-size: 14px;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.form-card h1 {
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  margin-bottom: 40px;
  padding-top: 0px;
  text-align: center; /* Center align the title */
}

input[type="text"], input[type="email"] {
  width: 90%; /* Responsive width */
  padding: 12px 20px;
  margin: 8px auto; /* Auto margins for center alignment */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  display: block; /* Block display to stack inputs */
}

input[type="text"]::placeholder, input[type="email"]::placeholder {
  color: #888;
}

input[type="text"]:focus, input[type="email"]:focus {
  border-color: #555;
  outline: none;
  box-shadow: 0 0 8px rgba(0,0,0,0.1);
}

.card-elements {
  margin: 20px auto;
  padding: 15px;
  background: #000000;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgb(0, 0, 0);
}

.card-element label {
  display: block;
  margin-bottom: 10px;
  color: #969696;
}

button {
  width: 90%;
  padding: 12px 20px;
  font-size: 16px;
  background-color: #4e4d1b;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
  margin: 10px auto 20px;
}

button:hover {
  background-color: #8ca032;
}

button:disabled {
  background-color: #ccc;
  cursor: default;
}

.StripeElement {
  padding: 12px 10px;
  border: 1px solid #000000;
  border-radius: 13px;
  width: 90%; /* Responsive width */
  margin: 10px auto; /* Center alignment */
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border-color: #a0a0a0;
}

.StripeElement::placeholder {
  color: #888;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

@media (max-width: 768px) {
  .form-card h1 {
      font-size: 20px; /* Smaller font size for better readability */
  }
}

@media (max-width: 480px) {
  .form-card h1 {
      font-size: 18px; /* Even smaller font size for mobile devices */
  }
  input[type="text"], input[type="email"] {
      padding: 10px 15px; /* Smaller padding for compact appearance */
      font-size: 14px; /* Smaller font size for better readability */
  }
  button {
      padding: 10px 25px; /* Adjust button padding for better fit */
      font-size: 14px; /* Adjust font size for readability */
  }
}
