.MovieCard {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  position: relative;
  width: 100vw;  
  height: auto; /* Change from 100vh to auto to allow content height to dictate overall height */
  min-height: 100vh; /* Ensures it's at least the height of the viewport */
  margin: 0;
  overflow: visible; /* Make sure overflow is visible or auto */
}

.backgroundImg {
  position: absolute; /* Ensures it covers the MovieCard */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover; /* Ensures the image covers the full view */
}

.imgGradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, rgba(0,0,0,1), transparent 90%);
}

.MovieCard_content {
  position: relative; /* Changed from absolute to relative */
  color: white; 
  text-align: center;
  z-index: 10; 
  width: 85%; /* Adaptive width for better responsiveness */
  padding: 20px; /* Adds padding for readability */
  margin: auto; /* Centers the content */
  margin-top: 60vh; /* Adjust based on the content's visual needs */
}

.MovieCard_title, .MovieCard_description, .MovieCard_eventDetails, .MovieCard_button {
  margin-bottom: 20px; /* Consistent spacing for elements */
}

/* Ensure button is accessible */
.MovieCard_button {
  display: inline-block; /* Adjust as needed for layout */
  font-size: 1em;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.MovieCard_button:hover {
  background-color: #0056b3;
}

/* Media Queries for responsive adjustments */
@media (max-width: 768px) {
  .MovieCard_content {
    width: 95%; /* More width for smaller screens */
  }
}

@media (max-width: 480px) {
  .MovieCard_content {
    width: 95%; /* Adjust width and margin for very small screens */
    margin-top: 50vh; /* Less vertical space on mobile devices */
  }
}
