/* NavigationBar.css */
.navBar {
    font-family: 'Courier New', Courier, monospace;
    background-color: #ffffff; /* Dark background for the nav bar */
    color: rgb(0, 0, 0);
    padding: 10px 30px; /* Adjusted padding for better spacing */
    text-align: center;
    width: 100%; /* Ensures it spans the full width of the viewport */
    position: fixed; /* Fixes the navbar at the top of the viewport */
    top: 0; /* Aligns the navbar at the top */
    left: 0; /* Aligns the navbar to the left edge of the viewport */
    z-index: 1000; /* Ensures the navbar stays above other content */
    box-shadow: 0 2px 4px rgba(0,0,0,0.5); /* Adds shadow for better visibility */
    display: flex; /* Added flex display to manage child elements */
    justify-content: space-between; /* Aligns children to the space between */
    align-items: center; /* Centers items vertically */
}

.navList {
    list-style: none;
    padding: 0;
    margin: 0; /* Removes default margin to align items properly */
    display: flex; /* Flex display to align nav items inline */
}

.navItem {
    margin: 10px; /* Spacing between items */
    cursor: pointer;
    transition: color 0.3s; /* Smooth transition for hover effects */
}

.navItem:hover {
    color: #ccc; /* Lighten color on hover for a visual feedback */
}

.navItem svg {
    margin-right: 0px; /* Adds a small space between the icon and the text */
    vertical-align: center; /* Aligns the icon vertically with the text */
}

.booknow {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.logo {
    height: 30px; /* Set a base height */
    width: auto; /* Maintain the aspect ratio */
    transform: scale(1.5); /* Scale the logo up */
    max-height: 60px; /* Ensure it does not exceed the navbar height */
    transition: transform 0.3s;
    margin-left: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 7px;
     /* Smooth transition for hover effects */
}

.logo:hover {
    transform: scale(1.6); /* Slightly enlarge the logo on hover for visual feedback */
}
